import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  TrashIcon,
  PencilSquareIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import {
  Bars3BottomLeftIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import LayoutHead from "../Layout/LayoutHead";
import "react-toastify/dist/ReactToastify.css";
import { vishnuIds, websites } from "../../components/Menu/Menu";
import { DataTable, InputField, Modal, SearchBox } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import EditGuide from "../../components/EditGuide";
import MarkdownConverter from "./MarkdownConverter";
import SwitchToggle from "../../components/common/SwitchToggle";

export default function Websites({ show_navlabel, toggleNavbar }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [myData, setMyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState("");
  const [modalType, setModalType] = useState("newKey");
  const [updateKey, setUpdateKey] = useState("");
  const [updateValue, setUpdateValue] = useState("");
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);
  const secondId = vishnuIds.find((site) => site.domain === website);

  const fetchData = async () => {
    setMyData({});
    setLoading(true);
    try {
      const response = await axios.post(
        `https://api.logicalcrm.com/api/open/nation/get_data`,
        {
          domain: website,
          key: "data",
        }
      );
      setMyData(JSON.parse(response.data?.site_data) || {});
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [website]);

  const filteredData = Object.entries(myData).filter(([key, value]) => {
    return (
      key.toLowerCase().includes(searchQuery.toLowerCase()) ||
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleDeleteKey = (keyToDelete) => {
    setModalType("deleteKey");
    setKeyToDelete(keyToDelete);
    handleModal();
  };

  const handleConfirmDelete = (event) => {
    event.preventDefault();
    let newObj = { ...myData };
    delete newObj[keyToDelete];
    // return
    // Update the key-value pair in the local state
    setMyData(newObj);

    if (typeof newObj === "object") {
      newObj = JSON.stringify(newObj);
    }
    axios
      .post(
        `${
          website?.includes("localhost") ? "http://" : "https://www."
        }${website}/api/update_data`,
        {
          data: newObj,
          domain: website,
        },
        { headers: { Authorization: "Bearer AKIA3WTNB4VOJYIFVP7F" } }
      )
      .then((response) => {
        setMyData((prevData) => {
          const newData = { ...prevData };
          delete newData[keyToDelete];
          return newData;
        });
        setKeyToDelete("");
        handleModal();
      })
      .catch((error) => {
        console.error(error); // Handle errors as needed
        setKeyToDelete("");
        handleModal();
      });
  };

  const handleCreateNewKey = () => {
    setModalType("new-key");
    setNewKey("");
    setNewValue("");
    handleModal();
  };

  const SubmitNewKey = (event) => {
    event.preventDefault();
    let newObj = { ...myData };
    if (newKey) {
      newObj[newKey] = newValue;
    } else {
      newObj = newValue;
    }
    if (typeof newObj === "object") {
      newObj = JSON.stringify(newObj);
    }

    // Update the key-value pair in the local state
    setMyData((prevData) => ({
      ...prevData,
      [newKey]: newValue,
    }));

    // Make an API call to update the key-value pair on the server
    axios
      .post(
        `${
          website?.includes("localhost") ? "http://" : "https://www."
        }${website}/api/update_data`,
        {
          data: newObj,
          domain: website,
        },
        { headers: { Authorization: "Bearer AKIA3WTNB4VOJYIFVP7F" } }
      )
      .then(() => {
        toast.success("New Data Added");
        handleModal();
      })
      .catch((err) => toast.error(err));
  };

  const handleUpdateKey = (key, value) => {
    const modalType =
      key.endsWith("text_1") ||
      key.endsWith("text_2") ||
      key.startsWith("blog_") ||
      key === "privacy_policy" ||
      key === "terms_and_conditions" ||
      key === "home_text"
        ? "updateMarkdown"
        : "updateKeyValue";

    setModalType(modalType);
    setUpdateKey(key);
    setUpdateValue(value);
    handleModal();
  };

  const handleUpdateValue = (event) => {
    event.preventDefault();
    let newObj = { ...myData };
    newObj[updateKey] = updateValue;
    // return
    // Update the key-value pair in the local state
    setMyData((prevData) => ({
      ...prevData,
      [updateKey]: updateValue,
    }));
    if (typeof newObj === "object") {
      newObj = JSON.stringify(newObj);
    }
    // Make an API call to update the key-value pair on the server
    axios
      .post(
        `${
          website?.includes("localhost") ? "http://" : "https://www."
        }${website}/api/update_data`,
        {
          data: newObj,
          domain: website,
        },
        { headers: { Authorization: "Bearer AKIA3WTNB4VOJYIFVP7F" } }
      )
      .then(() => {
        setUpdateKey("");
        setUpdateValue("");
        handleModal();
        toast.success("Value updated successfully");
      })
      .catch((err) => toast.error(err));
  };

  const [enabled, setEnabled] = useState(false);

  return (
    <div className="bg-gray-100">
      <LayoutHead
        loading={loading}
        setModalType={setModalType}
        site={site}
        secondId={secondId}
      >
        <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              {" "}
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}

          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              href={`https://www.${site?.domain}`}
              rel="noreferrer"
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
            >
              <GlobeAltIcon className="text-gray-700 w-6 mt-1" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>

      {/* DataTable */}
      <div className="px-6 pt-6">
        <div className="flex items-center gap-3 mb-2">
          <SearchBox
            className="bg-white shadow-sm flex-1"
            placeholder="Search with key or value"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={handleCreateNewKey}
              className="btnPrimary text-base font-medium disabled:cursor-not-allowed w-fit"
              disabled={loading}
            >
              New tag
            </button>
            <button
              onClick={fetchData}
              className="btnPrimary bg-black text-base text-white px-3 active:scale-105 transition-all"
            >
              <ArrowPathIcon className={`w-4 ${loading && "animate-spin"}`} />{" "}
              Refresh
            </button>
          </div>
        </div>
        <DataTable
          tableHeight="h-[calc(100vh-220px)]"
          isLoading={loading}
          heads={["Sr#", "actions", "key", "value"]}
          items={filteredData.map(([key, value], index) => ({
            sr: index + 1,
            action: (
              <span className="h-full w-full flex items-center">
                <button
                  onClick={() => handleUpdateKey(key, value.toString())}
                  className="text-gray-400 hover:text-yellow-500"
                >
                  <PencilSquareIcon className="h-4" />
                </button>
                <button
                  onClick={() => handleDeleteKey(key)}
                  className="ml-2 text-gray-400 hover:text-red-400"
                >
                  <TrashIcon className="h-4" />
                </button>
              </span>
            ),
            key: key,
            value: value.length > 100 ? value.slice(0, 100) + "...." : value,
          }))}
        />
      </div>

      {modalType === "updateMarkdown" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleUpdateValue}
          className="max-w-screen-xl"
          modalType="updateKeyValue"
        >
          <div className="grid grid-cols-2 gap-7 items-center">
            <h2 className="text-xl font-bold mb-1 flex items-center">
              Update Tag Value{" "}
              <span className="flex items-center gap-1 ml-2 text-blue-500 font-medium">
                (Markdown) <CheckBadgeIcon className="w-5 " />
              </span>
            </h2>
            <div className="flex items-center justify-end gap-3">
              <EditGuide />
              <label htmlFor="new-key" className="inputLabel">
                Key Name
              </label>
              <input
                type="text"
                id="new-key"
                placeholder="Enter key tag"
                value={updateKey}
                onChange={(e) => setUpdateKey(e.target.value)}
                className="inputField mt-1 cursor-not-allowed"
                // disabled
              />
            </div>
          </div>
          <MarkdownConverter
            newValue={updateValue}
            setNewValue={setUpdateValue}
            height="h-[calc(100vh-280px)]"
          />
        </Modal>
      )}
      {modalType === "updateKeyValue" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleUpdateValue}
          className="max-w-2xl"
          modalType="updateKeyValue"
        >
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold mb-4">Update Tag Value</h2>
            <EditGuide />
          </div>
          <div className="grid gap-4">
            <div>
              <label htmlFor="new-key" className="inputLabel">
                Key Name
              </label>
              <input
                type="text"
                id="new-key"
                placeholder="Enter key tag"
                value={updateKey}
                onChange={(e) => setUpdateKey(e.target.value)}
                className="inputField mt-1 cursor-not-allowed"
                // disabled
              />
            </div>
            <div>
              <label className="inputLabel" htmlFor="updateValueInput">
                Key value:
              </label>
              <textarea
                id="updateValueInput"
                placeholder="Enter key value"
                className="inputField mt-1 h-64"
                value={updateValue}
                onChange={(e) => setUpdateValue(e.target.value)}
              />
            </div>
          </div>
        </Modal>
      )}
      {modalType === "new-key" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          disabled={newKey === "" && newValue === ""}
          handleModalSubmit={SubmitNewKey}
          className={enabled ? "max-w-screen-xl" : "max-w-3xl"}
          modalType="newKey"
        >
          <div className="flex items-center justify-between mb-5">
            <h2 className="text-xl font-semibold">Create New Tag</h2>
            <div className="flex items-center gap-5 justify-end">
              <div className="flex items-center text-lg font-medium gap-2">
                Markdown
                <SwitchToggle enabled={enabled} setEnabled={setEnabled} />
              </div>
              <EditGuide />
            </div>
          </div>
          <InputField
            label="New Key"
            placeholder="Enter key tag"
            value={newKey}
            onChange={(event) => setNewKey(event.target.value)}
            // required
          />
          {enabled ? (
            <MarkdownConverter
              newValue={newValue}
              setNewValue={setNewValue}
              height="h-[calc(100vh-340px)]"
            />
          ) : (
            <div className="mt-4">
              <label htmlFor="new-value" className="inputLabel">
                New value
              </label>
              <textarea
                id="new-value"
                placeholder="Enter above tag value here"
                className="inputField mt-1 h-64"
                value={newValue}
                onChange={(event) => setNewValue(event.target.value)}
                required
              />
            </div>
          )}
        </Modal>
      )}
      {modalType === "deleteKey" && (
        <Modal
          modalType="delete"
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleConfirmDelete}
          className="max-w-lg "
        >
          <span className="text-lg text-center">
            {`Are you sure you want to delete the key "${keyToDelete}"?`}
          </span>
        </Modal>
      )}
      {/* {caution && (
        <div className="w-full h-screen bg-gray-300/70 flex items-center justify-center fixed top-0 left-0 z-50">
          <div className="flex flex-col items-center gap-2 bg-white p-7 shadow-xl rounded-lg">
            <p className="text-lg text-center w-full">
              You must sync with database after you make changes in data.
            </p>
            <button
              type="button"
              onClick={() => setCaution(false)}
              className="btnPrimary text-sm mt-2 font-medium"
            >
              Ok, I understand
            </button>
          </div>
        </div>
      )} */}
    </div>
  );
}
